import { useEffect, useState } from 'react';

import { DynamicForm } from '@admin/molecules/DynamicForm';
import { BaseEntity, FormFields } from '@admin/types';

export interface Props<Entity, FormEntity> {
    id: string;
    action: string;
    handleSubmit: (data: FormEntity) => Promise<void>;
    bindDoSubmit: (callback: () => void) => void;
    entity?: Entity;
    formFields: FormFields;
    onDataInvalid?: () => void;
}

export function Form<Entity extends BaseEntity, FormEntity>({
    id,
    action,
    handleSubmit,
    bindDoSubmit,
    entity,
    formFields,
    onDataInvalid,
}: Props<Entity, FormEntity>) {
    const [formData, setFormData] = useState<FormEntity>();

    useEffect(() => {
        if (entity) setFormData(entity as unknown as FormEntity);
    }, [entity]);

    const onSubmit = (data: FormEntity) => {
        if (data) setFormData(data);
        return handleSubmit(data);
    };
    return (
        <DynamicForm
            id={id}
            items={formFields}
            data={formData}
            onSubmit={onSubmit}
            onDataInvalid={onDataInvalid}
            action={action}
            bindDoSubmit={bindDoSubmit}
        />
    );
}
